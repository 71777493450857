import React, { useState, useEffect, forwardRef } from 'react';
import { Card, Form, Button, Modal, Col, Row, ButtonGroup, Alert } from 'react-bootstrap';

import { useGestionStore } from 'hooks/useGestionStore';
import TablaReact from './TablaReact';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { BsFilePdf } from "react-icons/bs";
import CargandoTabla from './CargandoTabla';
import { useEmisionStore } from 'hooks/useEmisionStore';
import { BsFillFilePdfFill } from "react-icons/bs";

import moment, { now } from 'moment-timezone';
import axios from 'axios';
import { useCincoStore } from 'hooks/useCincoStore';
import TablaVacia from './TablaVacia';
import { date } from 'is_js';
import TablaReact2 from 'components/siniestros/home/TablaReact2';

const aseguradorasImg = {
  "SURA" : '../aseguradoras/sura_color.png',
  "SURA-AUTOCLICK" : '../aseguradoras/sura_color.png',
  "ofcotiza_asegnom" : '../aseguradoras/liberty_color.png',
  "REALE" : '../aseguradoras/reale_color.png',
  "BCI" : '../aseguradoras/bci.png',
  "FID" : '../aseguradoras/fid_color.png',
  "LIBERTY" : '../aseguradoras/liberty_color.png'
  
}

const aseguradorasPDF = {
  "SURA_Taller Multimarca_0" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_0.pdf',
  "SURA_Taller Multimarca_3" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_3.pdf',
  "SURA_Taller Multimarca_5" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_5.pdf',
  "SURA_Taller Multimarca_10" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_10.pdf',
  "SURA_Taller Multimarca_20" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_20.pdf',
  "SURA_Taller Marca_0"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_0.pdf',
  "SURA_Taller Marca_3"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_3.pdf',
  "SURA_Taller Marca_5"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_5.pdf',
  "SURA_Taller Marca_10"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_10.pdf',
  "SURA_Taller Marca_20"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_20.pdf',
  "SURA_Taller Marca Garantía_0" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_0.pdf',
  "SURA_Taller Marca Garantía_3" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_3.pdf',
  "SURA_Taller Marca Garantía_5" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_5.pdf',
  "SURA_Taller Marca Garantía_10" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_10.pdf',
  "SURA_Taller Marca Garantía_20" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_20.pdf',
  "REALE_Taller Marca" : 'https://estoyseguro.cl/includes/terminos_auto_reale_plan_Taller_Aseguradora.pdf',
  "REALE_Taller Multimarca" : 'https://estoyseguro.cl/includes/terminos_auto_reale_plan_Taller_Aseguradora.pdf',
  "REALE_Exclusividad Taller" : 'https://estoyseguro.cl/includes/terminos_auto_reale_plan_Exclusividad_de_Taller.pdf',
  "Auto Preferente - T. Marca EstoySeguro_FID_Taller Marca" : 'https://estoyseguro.cl/cinco/includes/terminos_auto_fid_plan_Auto_Preferente_-_T._Marca_EstoySeguro.pdf',
  "Auto Preferente - T. MultiMarca EstoySeguro_FID_Taller Multimarca" : 'https://estoyseguro.cl/cinco/includes/terminos_auto_fid_plan_Auto_Preferente_-_T._MultiMarca_EstoySeguro.pdf',
  "Auto Conveniente - EstoySeguro_FID_Taller Multimarca" : 'https://estoyseguro.cl/cinco/includes/terminos_auto_fid_plan_Auto_Conveniente_-_EstoySeguro.pdf',
  "LIBERTY_Taller Multimarca" : 'https://estoyseguro.cl/includes/terminos_auto_liberty_plan_Conosur_estandar.pdf',
  "LIBERTY_Taller Marca" : 'https://estoyseguro.cl/includes/terminos_auto_liberty_plan_Conosur_Plus.pdf',
  "LIBERTY_Exclusividad Taller" : 'https://estoyseguro.cl/includes/terminos_auto_liberty_plan_Conosur_Premium.pdf',
}

const unificarNombres = {
  'Taller marca' : 'Taller Marca',
  'Taller Marca' : 'Taller Marca',
  'Taller de Marca' : 'Taller Marca',
  'Taller Garantía' : 'Taller Marca Garantía',
  'Taller de Garantía' : 'Taller Marca Garantía',
  'Exclusividad de Taller' : 'Exclusividad Taller',
  'libre eleccion' : 'Exclusividad Taller',
  'Taller Multimarca' : 'Taller Multimarca',
  'Taller MultiMarca' : 'Taller Multimarca',
  'Taller Aseguradora' : 'Taller Multimarca'
}

const TablaEmisiones = ({ nombre, ejecutivos, bitacora_status, bitacora_tipo_cotizante }) => {
  moment.tz.setDefault('America/Santiago');


  const [cargar, setCargar] = useState(true)





  const [filterEmis, setFilterEmis] = useState('');
  const [cotiza_auto, setCotiza_auto] = useState('');
  
  const [bitacora, setBitacora] = useState()
  const [obtenerOfertas, setObtenerOfertas] = useState()

  const [tipoCotizante, setTipoCotizante] = useState(''); // Estado para el tipo de cotizante
  const [status, setStatus] = useState(''); // Estado para el estado/status
  const [comentario, setComentario] = useState(''); // Estado para el comentario

  const [startDate, setStartDate] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);

  const [obtenerAuto, setObtenerAuto] = useState([])
  const [ejercutivosData, setEjercutivosData] = useState()
  const [bitacora_statusData, setBitacora_statusData] = useState()
  const [bitacora_tipo_cotizanteData, setBitacora_tipo_cotizanteData] = useState()

  const [dataCambio, setDataCambio] = useState([])

  const { dataInicio, dataFinal } = useSelector(state => state.datapicker);

  const [deducibleSLT, setDeducibleSLT] = useState('')
  const [aseguradoraSLT, setAseguradoraSLT] = useState('')
  const [tallerSLT, setTallerSLT] = useState('')

  const [uniqueAsegnomArray1, setUniqueAsegnomArray1] = useState()
  const [uniqueAsegnomArray2, setUniqueAsegnomArray2] = useState()
  const [uniqueAsegnomArray3, setUniqueAsegnomArray3] = useState()


  const [selectModalEjec, setSelectModalEjec] = useState({})
  const [actualizarBit, setActualizarBit] = useState(false)
  const [originalOfertas, setOriginalOfertas] = useState([]);

  const [selectedRadioValue, setSelectedRadioValue] = useState(null);
  
  const [bancosPago, setBancosPago] = useState()

  const [infoPlanes, setInfoPlanes] = useState([])
// Callback function to receive selected radio button value
  const handleRadioChange = (value) => {
    setSelectedRadioValue(value);
  };

  const {
    startObterner_bitacora,
    startObtener_ofertas,
    startObtenerAuto,
    startGuarfarBitacora,
    startObtenerCotizaciones,
    startObtenerDatos,
    startModificarEjecutivo,
    startModificarPagos,
    startObtenerBancosPago,
    startDarDeBaja,
    startObtenerDocumentosEmision,
    startInsertDocumentosEmision,
    startBuscarPoliza,
    startInfoPlanes
    // Otros métodos del useGestionStore
  } = useGestionStore();

  const {
    startInsertarFoto,
    startSubirFactura,
    startSubirEndoso,
    startSubirOtro,
    startPolizaSura,
    startPolizaSuraClick,
    startPolizaMapfre,
    startPolizaLiberty,
    startPolizaReale
    // Otros métodos del useGestionStore
  } =
  useCincoStore();
  const {
    startObtenerTablaEmision,
    startObtenerEmision,
    

  } = useEmisionStore();
  const { user } = useSelector(state => state.login);
  useEffect(async() => {
    // Función para cargar los datos iniciales
    const fetchData = async () => {
      try {
        // Obtener datos de ejecutivos, status y tipo de cotizante
        const [ejecutivosDataInit, Bitacora_status, Bitacora_tipo_cotizante] = await Promise.all([
          ejecutivos,
          bitacora_status,
          bitacora_tipo_cotizante
        ]);

        setEjercutivosData(ejecutivosDataInit);
        setBitacora_statusData(Bitacora_status);
        setBitacora_tipo_cotizanteData(Bitacora_tipo_cotizante);
        
        let nuevasCotizaciones = await startObtenerTablaEmision(dataInicio, dataFinal,user.rol === '2' || user.rol === '3'?user.name:'');

        if(nuevasCotizaciones){
          const newData = nuevasCotizaciones.data.map(item => {
            const lowerCaseObject = {};
            for (const key in item) {
              if (Object.prototype.hasOwnProperty.call(item, key)) {
                const value = item[key];
                lowerCaseObject[key] = typeof value === 'string' ? value.toLowerCase() : value;
              }
            }
            return lowerCaseObject;
          });
          setDataCambio(newData);
        }else{
          setDataCambio([]);
          setCargar(false)
        }


        let listaBancosPago = await startObtenerBancosPago()
        setBancosPago(listaBancosPago.data)

      } catch (error) {
        console.error('Error al cargar los datos iniciales:', error);
      }
    };

    fetchData();
    
  }, [dataInicio, dataFinal]);


  useEffect(async () => {
    
    let ejercutivosDataInit = await ejecutivos;
    if (ejercutivosDataInit) {
        setEjercutivosData(ejercutivosData);
    } else {
        setEjercutivosData([]);
    }

    let Bitacora_status = await bitacora_status;
    if (Bitacora_status) {
        setBitacora_statusData(Bitacora_status);
    } else {
        setBitacora_statusData([]);
    }

    let Bitacora_tipo_cotizante = await bitacora_tipo_cotizante;
    if (Bitacora_tipo_cotizante) {
        setBitacora_tipo_cotizanteData(Bitacora_tipo_cotizante);
    } else {
        setBitacora_tipo_cotizanteData([]);
    }
    let nuevasCotizaciones = await startObtenerTablaEmision(dataInicio, dataFinal,user.rol === '2' || user.rol === '3'?user.name:'');
    if(nuevasCotizaciones){
      const newData = nuevasCotizaciones.data.map(item => {
        const lowerCaseObject = {};
        for (const key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            const value = item[key];
            lowerCaseObject[key] = typeof value === 'string' ? value.toLowerCase() : value;
          }
        }
        return lowerCaseObject;
      });
      setDataCambio(newData);
    }else{
      setDataCambio([]);
      setCargar(false)
    }

  }, [actualizarBit]);

  

  const columns2 = React.useMemo(
    () => [
      {
        Header: 'Aseguradora',
        accessor: 'ofcotiza_asegnom',
        Cell: ({ row }) => (
          <Row>
            <Col className='text-center contenedorImgOfertas' >
                <img src={aseguradorasImg[row.original.ofcotiza_asegnom]} className='imgOfertas' />
            </Col>
          </Row>
          
        ),
      },
      
      {
        Header: 'Deducible',
        accessor: 'ofcotiza_deducible',
      },
      {
        Header: 'Taller',
        accessor: 'ofcotiza_taller',
      },
      {
        Header: 'Total Bruto',
        accessor: 'ofcotiza_valor_total',
      },
      {
        Header: 'Cuotas',
        accessor: 'ofcotiza_cuotas_max',
      },
      {
        Header: 'Mensual UF',
        accessor: 'ofcotiza_valor_cuota',
      },
/*           {
        Header: 'Mensual $',
        accessor: 'ofcotiza_valor_cuota',
      }, */
      {
        Header: 'Auto Remplazo',
        accessor: 'ofcotiza_auto_reemplazo',
      },
      {
        Header: 'PDF',
        Cell: ({ row }) => (
          <Row className='text-center'>
            <a target='_blank' href={row.original.ofcotiza_asegnom=="SURA"?aseguradorasPDF[row.original.ofcotiza_asegnom+"_"+row.original.ofcotiza_taller+"_"+row.original.ofcotiza_deducible]:row.original.ofcotiza_asegnom=="FID"?aseguradorasPDF[row.original.ofcotiza_nombre_produc+"_"+row.original.ofcotiza_asegnom+"_"+row.original.ofcotiza_taller]:aseguradorasPDF[row.original.ofcotiza_asegnom+"_"+row.original.ofcotiza_taller]} ><BsFilePdf class="iconoTablaPdf" /></a>
          </Row>
          
        ),
      },
      {
    Header: 'Seleccione',
    Cell: ({ row }) => (
      <div className="d-grid ">
          <Form.Check
                      
                      type={'radio'}
                      label={`Coberturas`}
                      id={`Radio${row.original.ofcotiza_id}`}
                    />
      </div>

    ),
  },
      // ... Add more columns as needed
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Emisión',
        accessor: 'emision_id',
      },
      {
        Header: 'Nombre',
        accessor: 'clie_nombre',
      },
      {
        Header: 'Apellido',
        accessor: 'clie_paterno',
      },
      {
        Header: 'Rut',
        accessor: 'cliente_clie_rut',
      },
      {
        Header: 'Origen',
        accessor: 'origen_nombre',
      },
      {
        Header: 'Póliza',
        accessor: 'emision_poliza',
      },
      {
        Header: 'Aseguradora',
        accessor: 'aseg_nombre',
      },
      {
        Header: 'Ejecutivo',
        accessor: 'cotiza_responsable',
        
      },
      {
        Header: 'Fecha',
        accessor: 'emision_fecha',
        Cell: ({ value }) => {

          const fechaHoraOriginal = moment(value,'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
          const DIFF_HORA_GLOBAL = parseInt(process.env.DIFF_HORA_GLOBAL, 10);
          const fechaHoraRestada = fechaHoraOriginal.subtract(DIFF_HORA_GLOBAL, 'hours');
          const fechaHoraFormateada = fechaHoraRestada.format('DD/MM/YYYY HH:mm');
       

          return fechaHoraFormateada
        }, // Formatea la fecha usando moment
      
      },

      {
        Header: 'Acción',
        Cell: ({ row }) => (
          <div className="d-grid">
            <Button variant={row.original.tiene_emision==1?'success':'primary'} className='me-2 mb-1' size="sm" onClick={() => buscarEmis(row.original.emision_id)}>Detalle</Button>
            {
              row.original.tiene_pendiente == 1?<Button variant='light' className='me-2 mb-1' size="sm" >Pendiente</Button>:''
            }
            
          </div>

        ),
      },
      // ... Add more columns as needed
    ],
    []
  );

  const columns3 = React.useMemo(
    () => [
      {
        Header: 'N°',
        accessor: (row, index) => index + 1,
      },
      {
        Header: 'Iteración',
        accessor: 'bitacora_fecha',
        Cell: ({ value }) => moment(value).format('DD/MM/YYYY hh:ss'), // Formatea la fecha usando moment
      },

      {
        Header: 'Reagendamiento',
        accessor: 'bitacora_reagendado',
        Cell: ({ value }) => value?moment(value).format('DD/MM/YYYY hh:ss'):'', // Formatea la fecha usando moment
      },
      {
        Header: 'Tipo de Cotizante',
        accessor: 'tipo_descripcion',
      },
      {
        Header: 'Status',
        accessor: 'status_descripcion',
      },
      {
        Header: 'Comentario',
        accessor: 'bitacora_observacion',
      },

      // ... Add more columns as needed
    ],
    []
  );

  const columns4 = React.useMemo(
    () => [
      {
        Header: 'Cuota N°',
        accessor: (row, index) => index + 1,
      },
      {
        Header: 'Estado',
        accessor: 'subs_inv_status',
        Cell: ({ value }) => value==1?'pagado':'pendiente', // Formatea la fecha usando moment
      },

      {
        Header: 'Monto',
        accessor: 'subs_balance',
        Cell: ({ value }) => value?'$'+formatNumero(value):'', // Formatea la fecha usando moment
      },
      {
        Header: 'Fecha de Pago',
        accessor: 'subs_inv_period_start',
        Cell: ({ value }) => value?moment(value).format('DD/MM/YYYY hh:ss'):'', // Formatea la fecha usando moment
      },
      {
        Header: 'Fecha Pagado',
        accessor: 'subs_fecha_importe',
        Cell: ({ value }) => value?moment(value).format('DD/MM/YYYY hh:ss'):'', // Formatea la fecha usando moment
      },

      // ... Add more columns as needed
    ],
    []
  );




    const [documentoEmision, setDocumentoEmision] = useState()
  const buscarEmis = async(emision_id) =>{
    setModalShow2(true)
    let nEmis=emision_id
    let nuevasEmisiones = await startObtenerEmision(nEmis);
    setFilterEmis(nuevasEmisiones?.data[0])
  
    let documentoEmisionLet = await startObtenerDocumentosEmision(nEmis);
    
    
      let newData = [];
  

      if (nuevasEmisiones?.data[0].aseg_nombre == "SURA") {
        newData.push({
          id: nuevasEmisiones?.data[0].id,
          poliza_emision: nuevasEmisiones?.data[0].poliza_emision,
          cotizacion_emision: nuevasEmisiones?.data[0].cotizacion_emision,
          oferta_cotizacion: nuevasEmisiones?.data[0].oferta_cotizacion,
          emision_cotizacion: nuevasEmisiones?.data[0].emision_cotizacion,
          tipo_documento: "Condicionado",
          url: 'https://estoyseguro.cl/includes/Condicionado_Sura_'+nuevasEmisiones?.data[0].ofcotiza_plan_nombre?.replace(/[_]/g, '%20')+'_'+nuevasEmisiones?.data[0].ofcotiza_deducible+'.pdf',
          fecha_documento: nuevasEmisiones?.data[0].fecha_documento,
        });
      }

     
      if (nuevasEmisiones?.data[0].emision_pdf_poliza !== '' &&  nuevasEmisiones?.data[0].emision_pdf_poliza !== null) {
        newData.push({
          id: nuevasEmisiones?.data[0].id,
          poliza_emision: nuevasEmisiones?.data[0].poliza_emision,
          cotizacion_emision: nuevasEmisiones?.data[0].cotizacion_emision,
          oferta_cotizacion: nuevasEmisiones?.data[0].oferta_cotizacion,
          emision_cotizacion: nuevasEmisiones?.data[0].emision_cotizacion,
          tipo_documento: "Poliza",
          url: nuevasEmisiones?.data[0].emision_pdf_poliza,
          fecha_documento: nuevasEmisiones?.data[0].fecha_documento,
        });
      }else{
        if('SURA' !== nuevasEmisiones?.data[0].aseg_nombre){
          newData.push({
        
            poliza_emision: nuevasEmisiones?.data[0].emision_poliza,
            cotizacion_emision: nuevasEmisiones?.data[0].emision_id,
            oferta_cotizacion: nuevasEmisiones?.data[0].emision_oferta,
            emision_cotizacion: nuevasEmisiones?.data[0].cotiza_id,
            tipo_documento: "Sin Documento"
          });
        }
  

      }

      if (documentoEmisionLet?.data.length > 0 ){
        documentoEmisionLet.data.map((item,key) => {
          newData.push({
            id: nuevasEmisiones?.data[0].id,
            poliza_emision: item.poliza_emision,
            cotizacion_emision: item.cotizacion_emision,
            oferta_cotizacion: item.oferta_cotizacion,
            emision_cotizacion: item.emision_cotizacion,
            tipo_documento: item.tipo_documento,
            url: item.url,
            fecha_documento: item.fecha_documento,
          });
        })

      }


      
      setDocumentoEmision(newData)

      let temp2 = await startInfoPlanes(nuevasEmisiones?.data[0].cotiza_id)
      if(temp2){
        setInfoPlanes(temp2.data)
     
      }

    
    
   

  }

  const enviarURlEspecial = () =>{
    const varBtoa = window.btoa(filterCotiz.cotiza_id)
    
    const url = `https://estoyseguro.cl/seguro-automotriz-emision.php?var1=${varBtoa}`;


    window.open(url,"_blank");
  }


  const modificarEjecutivoChange = async(cotiza_id) => {

    let respuestaUpdate = await startModificarEjecutivo(cotiza_id, selectModalEjec.selectedId,selectModalEjec.selectedName)

    if(respuestaUpdate.data.affectedRows > 0){
      let nuevasCotizaciones = await startObtenerDatos(cotiza_id);
      let initFiltro = nuevasCotizaciones.data.find(buscar => buscar.cotiza_id == cotiza_id)
      setFilterEmis(initFiltro);
      setActualizarBit(!actualizarBit)
      Swal.fire("Cambio Correctamente","Ejecutivo: "+selectModalEjec.selectedName,"success")
    }
  }
  const selectChange = (event) => {
    const selectedId = event.target.value;
    const selectedName = event.target.options[event.target.selectedIndex].text;
    setSelectModalEjec({selectedId,selectedName})
  }
  
  const handleClose = () => {
    setModalShow2(false)
    setFilterEmis([])
    setSelectedRadioValue(null)
    setDeducibleSLT('')
    setAseguradoraSLT('')
    setTallerSLT('')
    setDocumentoEmision([])
    setInfoPlanes([])
   
  }
  const handleClose2 = () => {
    setModalShow(false)
  }

  const modal2 = (filterEmis) => {
    setModalShow(true)
    setEditVarNombre(filterEmis.pago_nombre)
    setEditVarRut(filterEmis.pago_rut)
    setEditVarPaterno(filterEmis.pago_paterno)
    setEditVarMaterno(filterEmis.pago_materno)
    setEditTipoPago(filterEmis.pago_tipo)
    setEditVarBanco(filterEmis.banco_nombre)
    setEditVarCuotas(filterEmis.pago_cuotas)
    setEditVarDia(filterEmis.pago_dia)
    setEditPrimerVenc(filterEmis.pago_prim_venc)
    setEditTarjetaPago(filterEmis.pago_tarjeta_tipo)
    setEditNumeroTarjeta(filterEmis.pago_cuenta_nro)
    setEditVarVencimiento(filterEmis.pago_tarjeta_vence)
    setEditNumeroCuenta(filterEmis.pago_cuenta_nro)
    setEditVarEmail(filterEmis.pago_correo)
  }


  const [editVarNombre, setEditVarNombre] = useState()
  const [editVarRut, setEditVarRut] = useState()
  const [editVarPaterno, setEditVarPaterno] = useState()
  const [editVarMaterno, setEditVarMaterno] = useState()
  const [editTipoPago, setEditTipoPago] = useState()
  const [editVarBanco, setEditVarBanco] = useState()
  const [editVarCuotas, setEditVarCuotas] = useState()
  const [editVarDia, setEditVarDia] = useState()
  const [editPrimerVenc, setEditPrimerVenc] = useState()
  const [editTarjetaPago, setEditTarjetaPago] = useState()
  const [editNumeroTarjeta, setEditNumeroTarjeta] = useState()
  const [editVarVencimiento, setEditVarVencimiento] = useState()
  const [editNumeroCuenta, setEditNumeroCuenta] = useState()
  const [editVarEmail, setEditVarEmail] = useState()
  const [tipo_documento, setTipo_documento] = useState()
  const [detalle, setDetalle] = useState()
  const [Motivo, setMotivo] = useState()
  const [fechaBaja, setFechaBaja] = useState()

  const editarPagoHandle = async(emision_id) => {
      const editarResp = await startModificarPagos(emision_id, editVarRut, editVarNombre, editVarPaterno,
        editVarMaterno,
        editTipoPago,
        editVarBanco,
        editVarCuotas,
        editVarDia,
        editPrimerVenc,
        editTarjetaPago,
        editNumeroTarjeta,
        editVarVencimiento,
        editNumeroCuenta,
        editVarEmail)

      if(editarResp){
        Swal.fire("Editado Correctamente","","success")
        buscarEmis(emision_id)
      }

  }

  const [modalShow3, setModalShow3] = useState(false)

  const handleClose3 = ()  => {
    setModalShow3(!modalShow3)
  }

  const [modalShow4, setModalShow4] = useState(false)
  
  const handleClose4 = ()  => {
    setModalShow4(!modalShow4)
  }
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    // Actualiza el estado 'selectedFile' con el archivo seleccionado por el usuario.
    setSelectedFile(e.target.files[0]);
  };

  const formatNumero = (number) => {
    if (number !== undefined) {
        const parts = number.toString().split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        let decimalPart = parts[1] ? ',' + parts[1] : '';

        if (decimalPart.length > 1) {
            // Si la longitud del decimal es mayor a 1, redondea a un decimal
            decimalPart = ',' + parseFloat('0.' + parts[1]).toFixed(1).split('.')[1];
        }

        return integerPart + decimalPart;
    }
}

  const handleEnviarImagen = async(cotizacion,
    oferta,
    poliza,
    emision,
    aseguradora) => {
    if (selectedFile) {

        // Aquí puedes enviar 'selectedFile' al servidor en una solicitud POST.
        // Puedes utilizar la API Fetch o una biblioteca como Axios para hacer la solicitud.

        // Ejemplo con Axios:
        const formData = new FormData();
        formData.append('auto_fact_cargar', selectedFile);
        formData.append('auto_fact_cotizacion', cotizacion);
        formData.append('auto_fact_oferta', oferta);
        formData.append('auto_fact_emisor', poliza);
        formData.append('auto_fact_numero', emision);
        formData.append('tipo_documento', tipo_documento);
       

        switch(tipo_documento){
          case 'poliza':
          
            switch(aseguradora){
              case 'SURA' :
                const insertarPolizaSura = await startPolizaSura(formData)

                if(insertarPolizaSura){
                  
                  startPolizaSura(poliza, emision,
                                              oferta, cotizacion,
                                              tipo_documento, insertarPolizaSura.urlSuccess)
                  Swal.fire("Mandato Enviado Correctamente","","success")
                }
              break;

              case 'SURA-AUTOCLICK' :
                const insertarPolizaSuraClick = await startPolizaSuraClick(formData)

                if(insertarPolizaSuraClick){
                  
                  startInsertDocumentosEmision(poliza, emision,
                                              oferta, cotizacion,
                                              tipo_documento, insertarPolizaSuraClick.urlSuccess)
                  Swal.fire("Mandato Enviado Correctamente","","success")
                }
              break;

              case 'MAPFRE' :
                const insertarPolizaMapfre = await startPolizaMapfre(formData)

                if(insertarPolizaMapfre){
                  
                  startInsertDocumentosEmision(poliza, emision,
                                              oferta, cotizacion,
                                              tipo_documento, insertarPolizaMapfre.urlSuccess)
                  Swal.fire("Mandato Enviado Correctamente","","success")
                }
              break;

              case 'LIBERTY' :
                const insertarPolizaLiberty = await startPolizaLiberty(formData)

                if(insertarPolizaLiberty){
                  
                  startInsertDocumentosEmision(poliza, emision,
                                              oferta, cotizacion,
                                              tipo_documento, insertarPolizaLiberty.urlSuccess)
                  Swal.fire("Mandato Enviado Correctamente","","success")
                }
              break;

              case 'REALE' :
                const insertarPolizaReale = await startPolizaReale(formData)

                if(insertarPolizaReale){
                  
                  startInsertDocumentosEmision(poliza, emision,
                                              oferta, cotizacion,
                                              tipo_documento, insertarPolizaReale.urlSuccess)
                  Swal.fire("Mandato Enviado Correctamente","","success")
                }
              break;
              
              
            }
          
          break;


          case 'mandato':
            const insertarFoto = await startInsertarFoto(formData)

            if(insertarFoto){
              
              startInsertDocumentosEmision(poliza,
                emision,
                oferta,
          cotizacion,
          tipo_documento,
          insertarFoto.urlSuccess)
              Swal.fire("Mandato Enviado Correctamente","","success")
            }
          
          break;

          case 'factura' :
            const SubirPoliza = await startSubirFactura(formData)

            if(SubirPoliza){
              
              startInsertDocumentosEmision(poliza,
                emision,
                oferta,
          cotizacion,
          tipo_documento,
          SubirPoliza.urlSuccess)
              Swal.fire("Mandato Enviado Correctamente","","success")
            }
            break;
          
          case 'endoso' :
            
            const SubirEndoso = await startSubirEndoso(formData)
          
            if(SubirEndoso){
            
            startInsertDocumentosEmision(poliza,
              emision,
              oferta,
        cotizacion,
        tipo_documento,
        SubirEndoso.urlSuccess)
              Swal.fire("Mandato Enviado Correctamente","","success")
            }
          break;

          case 'otros' :
            
            const SubirOtros = await startSubirOtro(formData)

            if(SubirOtros){
             
              startInsertDocumentosEmision(poliza,
                emision,
                oferta,
          cotizacion,
          tipo_documento,
          SubirOtros.urlSuccess)
              Swal.fire("Mandato Enviado Correctamente","","success")
            }
          break;

          
            
        }
        buscarEmis(emision)

    }
    
};

  const darDeBaja = async(emision_id) => {
      const respDarBaja = await startDarDeBaja(emision_id, detalle, Motivo, user.name, fechaBaja)
        
        if(respDarBaja?.ok){
          Swal.fire("Emision dada de baja correctamente","Estado: "+detalle + " Motivo: "+Motivo, "success")
        }

  }

  const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
      <Form.Control
        
        ref={ref}
        onClick={onClick}
        value={value}
        className="ps-4"
        onChange={(e) => {
      
        }}
      />
      <FontAwesomeIcon
        icon="calendar-alt"
        className="text-primary position-absolute top-50 translate-middle-y ms-2"
      />
    </div>
  ));


  const [previousData, setPreviousData] = useState(dataCambio); // Variable para almacenar la data anterior

  const handlePolizaSearch = async (poliza) => {

      if(poliza==undefined){
        setDataCambio(previousData)
      }else{
        let varTemp = await startBuscarPoliza(poliza);
        if(varTemp){
          setPreviousData(dataCambio)

          setDataCambio(varTemp?.data);
        }
      }


    }
  
  

  return (
    <>
    <Card className="h-100">
      <Card.Header className="pb-0">
        <h6 className="mb-5 mt-2">{nombre}</h6>
      </Card.Header>
      {
         Object.keys(dataCambio).length>0?<TablaReact  Ncolum={20}  columns={columns} data={dataCambio} onPolizaSearch={handlePolizaSearch} />:cargar?<CargandoTabla columns={columns} />:<TablaVacia columns={columns} />
      }
      
    </Card>

    <Modal
    show={modalShow2}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={handleClose}
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">DETALLES DE LA EMISIÓN</Modal.Title>
    </Modal.Header>
    <Modal.Body key={1}>
    <Row key={1} className="p-2 mb-1">
      {
        Object.keys(filterEmis).length>0?(
          <>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="CotizaID">
                  <Form.Label>Cotización ES</Form.Label>
                  <p> {filterEmis.cotiza_id} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="EmisionID">
                  <Form.Label>Emisión ES</Form.Label>
                  <p> {filterEmis.emision_id} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="Poliza">
                  <Form.Label>Póliza</Form.Label>
                  <p> {filterEmis.emision_poliza} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="Patente">
                  <Form.Label>Patente</Form.Label>
                  <p> {filterEmis.emision_patente} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="RUT">
                  <Form.Label>RUT</Form.Label>
                  <p> {filterEmis.cliente_clie_rut} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="Compañia">
                  <Form.Label>Compañia</Form.Label>
                  <p> {filterEmis.aseg_nombre} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="Origen">
                  <Form.Label>Origen</Form.Label>
                  <p> {filterEmis.origen_nombre} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="FechaE">
                  <Form.Label>Fecha Emisión</Form.Label>
                  <p> {moment(filterEmis.emision_fecha).format("DD/MM/YYYY")} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Inicio Vigencia</Form.Label>
                  <p> {moment(filterEmis.emision_fecha_re).format("DD/MM/YYYY")} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="TerminoV">
                  <Form.Label>Término Vigencia</Form.Label>
                  <p> {moment(filterEmis.fin_fecha_re).format("DD/MM/YYYY")} </p>
                </Form.Group>
            </Col>
            {
              filterEmis.fecha_baja?(
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="baja">
                      <Form.Label>Fecha Baja</Form.Label>
                      <p> {moment(filterEmis.fecha_baja).format("DD/MM/YYYY")} </p>
                    </Form.Group>
                </Col>
              ):''
            }
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="EstadoP">
                  <Form.Label>Estado Póliza</Form.Label>
                  <p> {filterEmis.detalle} </p>
                </Form.Group>
            </Col>
            {
              filterEmis.cotiza_vendedor_id?(
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="Embajador">
                      <Form.Label>Embajador / Seller</Form.Label>
                      <p> {filterEmis.cotiza_vendedor_id} </p>
                    </Form.Group>
                </Col>
              ):''
            }
            {
              user.rol === "1"?(
                <Col xs={6} className="p-2 ">
                <Row className="p-2 mb-1">
                    <Col xs={6} className="p-2 ">
                        <Form.Group className="mb-3  text-center" controlId="Poliza">
                          <Form.Label>Anular / Cancelar</Form.Label>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                      <Button onClick={() => handleClose4()}>Dar de Baja</Button>
                    </Col>
                </Row>
              </Col>
              ):''
            }

            <hr />
            <h3 className='mt-5 mb-5'>DATOS ASEGURADO</h3>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>RUT</Form.Label>
                  <p> {filterEmis.cliente_clie_rut} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Nombre</Form.Label>
                  <p> {filterEmis.clie_nombre} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Apellido Paterno</Form.Label>
                  <p> {filterEmis.clie_paterno} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Apellido Materno</Form.Label>
                  <p> {filterEmis.clie_materno} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Sexo</Form.Label>
                  <p> {filterEmis.clie_sexo==="M"?"Mujer":"Hombre"} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Estado Civil</Form.Label>
                  <p> {filterEmis.clie_estadocivil} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>E-mail</Form.Label>
                  <p> {filterEmis.clie_email} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Teléfono</Form.Label>
                  <p> {filterEmis.clie_telefono} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Celular</Form.Label>
                  <p> {filterEmis.clie_celular} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Calle</Form.Label>
                  <p> {filterEmis.clie_dir_calle} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Número</Form.Label>
                  <p> {filterEmis.clie_dir_num} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Depto.</Form.Label>
                  <p> {filterEmis.clie_dir_depto} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Fecha de Nacimiento</Form.Label>
                  <p> {filterEmis.clie_fechnac} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Region</Form.Label>
                  <p> {filterEmis.clie_region} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Ciudad</Form.Label>
                  <p> {filterEmis.clie_ciudad} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="InicioV">
                  <Form.Label>Comuna</Form.Label>
                  <p> {filterEmis.clie_comuna} </p>
                </Form.Group>
            </Col>
            <hr />
            <h3 className='mt-5 mb-5'>DATOS VEHICULO</h3>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="marca_nombre">
                  <Form.Label>Marca</Form.Label>
                  <p> {filterEmis.marca_nombre} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="modelo_nombre">
                  <Form.Label>Modelo</Form.Label>
                  <p> {filterEmis.modelo_nombre} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Año</Form.Label>
                  <p> {filterEmis.cotiza_anno} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Color</Form.Label>
                  <p> {filterEmis.color_nombre} </p>
                </Form.Group>
            </Col>
            
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>N° Chasis</Form.Label>
                  <p> {filterEmis.auto_chasis} </p>
                </Form.Group>
            </Col>

            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>N° Motor</Form.Label>
                  <p> {filterEmis.auto_motor} </p>
                </Form.Group>
            </Col>

            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Kilometraje</Form.Label>
                  <p> {filterEmis.auto_kms} </p>
                </Form.Group>
            </Col>

            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>¿Es dueño(a)?:</Form.Label>
                  <p> {filterEmis.auto_duenno} </p>
                </Form.Group>
            </Col>

            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Uso del vehiculo:</Form.Label>
                  <p> {filterEmis.auto_uso} </p>
                </Form.Group>
            </Col>
            
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Estado del vehiculo:</Form.Label>
                  <p> {filterEmis.auto_estado} </p>
                </Form.Group>
            </Col>
            <hr />
            <h3 className='mt-5 mb-5'>DATOS INSPECCION</h3>

            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Nombre:</Form.Label>
                  <p> {filterEmis.aux_paga_nombre +' '+filterEmis.aux_paga_paterno+' '+filterEmis.aux_paga_materno} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Celular:</Form.Label>
                  <p> {filterEmis.aux_paga_tel} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Teleno Fijo:</Form.Label>
                  <p> {filterEmis.aux_ins_telefono} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Direccion Contacto:</Form.Label>
                  <p> {filterEmis.aux_paga_calle} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Numero:</Form.Label>
                  <p> {filterEmis.aux_paga_num_calle} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Dpto:</Form.Label>
                  <p> {filterEmis.aux_paga_depto} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Comuna:</Form.Label>
                  <p> {filterEmis.comuna_nombre} </p>
                </Form.Group>
            </Col>

            <hr />
            <h3 className='mt-5 mb-5'>DATOS MEDIO DE PAGO</h3>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>RUT:</Form.Label>
                  <p> {filterEmis.pago_rut} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Nombre:</Form.Label>
                  <p> {filterEmis.pago_nombre} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Apellido Paterno:</Form.Label>
                  <p> {filterEmis.pago_paterno} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Apellido Materno:</Form.Label>
                  <p> {filterEmis.pago_materno} </p>
                </Form.Group>
            </Col>

            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Tipo de Pago:</Form.Label>
                  <p> {filterEmis.pago_tipo} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Banco:</Form.Label>
                  <p> {filterEmis.banco_nombre} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Cuotas:</Form.Label>
                  <p> {filterEmis.pago_cuotas} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Dia:</Form.Label>
                  <p> {filterEmis.pago_dia} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Primer Vencimiento:</Form.Label>
                  <p> {filterEmis.pago_prim_venc} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Tarjeta de Pago:</Form.Label>
                  <p> {filterEmis.pago_tarjeta_tipo} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Numero de Tarjeta:</Form.Label>
                  <p> {filterEmis.pago_cuenta_nro} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Vencimiento:</Form.Label>
                  <p> {filterEmis.pago_cuenta_nro} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>Número de Cuenta:</Form.Label>
                  <p> {filterEmis.pago_correo} </p>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="cotiza_anno">
                  <Form.Label>E-Mail:</Form.Label>
                  <p> {filterEmis.pago_fono} </p>
                </Form.Group>
            </Col>
            {
              user.rol !== "3"?(
                <Col xs={3} className="p-2 ">
                  <Button onClick={() => modal2(filterEmis)}>Modificar datos</Button>
                </Col>
              ):''
            }

            <hr />
            <h3 className='mt-5 mb-5'>DOCUMENTOS</h3>
            {
              documentoEmision?documentoEmision?.map((item,key) =>(
                <div key={key} className='flexMagic'>
                  <Col xs={2} className="p-2 ">
                      <Form.Group className="mb-3 cajasInfo text-center" controlId="cotiza_anno">
                        <Form.Label>{item.tipo_documento}:</Form.Label>
                        <a target='_blank' {...(item.url ? { href: item.url } : {})}><BsFillFilePdfFill style={{fontSize:'2.4rem'}}/></a>
                      </Form.Group>
                  </Col>
                  <Col  xs={2} className="p-2 ">
                      <Form.Group className="mb-3 cajasInfo text-center" controlId="cotiza_anno">
                        <Form.Label>Aseguradora:</Form.Label>
                        <img src={aseguradorasImg[filterEmis.aseg_nombre]}  style={{width:'5.1rem'}}/>
                        
                      </Form.Group>
                  </Col>
                  <Col  xs={2} className="p-2 ">
                      <Form.Group className="mb-3 cajasInfo text-center" controlId="cotiza_anno">
                        <Form.Label>Deducible en UF:</Form.Label>
                        <p>{filterEmis.emision_deducible}</p>
                      </Form.Group>
                  </Col>
                  <Col  xs={2} className="p-2 ">
                      <Form.Group className="mb-3 cajasInfo text-center" controlId="cotiza_anno">
                        <Form.Label>Deducible en UF:</Form.Label>
                        <p>{filterEmis.emision_total?.toFixed(1)}</p>
                      </Form.Group>
                  </Col>
                  <Col  xs={2} className="p-2 ">
                      <Form.Group className="mb-3 cajasInfo text-center" controlId="cotiza_anno">
                        <Form.Label>Valor Cuota:</Form.Label>
                        <p>{filterEmis.ofcotiza_valor_cuota?.toFixed(1)}</p>
                      </Form.Group>
                  </Col>
                  <Col  xs={2} className="p-2 ">
                      <Form.Group className="mb-3 cajasInfo text-center" controlId="cotiza_anno">
                        <Form.Label>Cuotas:</Form.Label>
                        <p>{filterEmis.ofcotiza_cuotas_max}</p>
                      </Form.Group>
                  </Col>
                </div>
              )):''
            }

            <Col xs={12} className="p-2">
              <Button style={{float:'right'}} onClick={() =>setModalShow3(true)}>Subir Documento</Button>
            </Col>

            

           
            {
              
              infoPlanes.length>0?(
                <>  
                  <hr className='mb-4'/>
                  <h3 className='mt-5 mb-5'>IMPORTES</h3>
                  <TablaReact2 size={'xl'} Ncolum={5} data={infoPlanes} columns={columns4} />
                </>
              ):''
            }

            

    
            

            
          </>
        ):(
          <>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="CotizaID">
                  <Form.Label>Cotización ES</Form.Label>
                </Form.Group>
            </Col>
              <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="EmisionID">
                  <Form.Label>Emisión ES</Form.Label>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="Poliza">
                  <Form.Label>Póliza</Form.Label>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="Patente">
                  <Form.Label>Patente</Form.Label>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="RUT">
                  <Form.Label>RUT</Form.Label>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="Compañia">
                  <Form.Label>Compañia</Form.Label>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="Origen">
                  <Form.Label>Origen</Form.Label>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="FechaE">
                  <Form.Label>Fecha Emisión</Form.Label>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="InicioV">
                  <Form.Label>Inicio Vigencia</Form.Label>
                </Form.Group>
            </Col>
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="TerminoV">
                  <Form.Label>Término Vigencia</Form.Label>
                </Form.Group>
            </Col>

            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="baja">
                  <Form.Label>Fecha Baja</Form.Label>
                </Form.Group>
            </Col>

            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="EstadoP">
                  <Form.Label>Estado Póliza</Form.Label>
                </Form.Group>
            </Col>
          
            <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo skeleton" controlId="Embajador">
                  <Form.Label>Embajador / Seller</Form.Label>
                </Form.Group>
            </Col>

          </>
        )
      }



      
      
    </Row>
    </Modal.Body>
    <Modal.Footer>
      <Row>
        <Col xs={12}>
            <ButtonGroup aria-label="Basic example">

            </ButtonGroup>

        </Col>
      </Row>


    </Modal.Footer>
    </Modal>


    <Modal
    show={modalShow}
    size="xl"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    onHide={handleClose2}
  >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Modificar</Modal.Title>
        </Modal.Header>
        <Modal.Body key={1}>
            <Row className="">
                <h5 className=''>DATOS MEDIO DE PAGO</h5>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>RUT:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editVarRut} onChange={(e) => setEditVarRut(e.target.value)} />
                    </Form.Group>
                </Col>
                
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>Nombre:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editVarNombre} onChange={(e) => setEditVarNombre(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>Apellido Paterno:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editVarPaterno} onChange={(e) => setEditVarPaterno(e.target.value)}  />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>Apellido Materno:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editVarMaterno} onChange={(e) => setEditVarMaterno(e.target.value)} />
                    </Form.Group>
                </Col>

                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>Tipo de Pago:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editTipoPago} onChange={(e) => setEditTipoPago(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2">
                  <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                    <Form.Label>Banco:</Form.Label>
                    <select
                      size="sm"
                      value={editVarBanco}
                      onChange={(e) => setEditVarBanco(e.target.value)} 
                    >
                      <option value="">Selecciona un banco</option>
                      {bancosPago?.map((banco, index) => (
                        <option key={index} value={banco.banco_id}>
                          {banco.banco_nombre}
                        </option>
                      ))}
                    </select>
                  </Form.Group>
                </Col>

                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>Cuotas:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editVarCuotas} onChange={(e) => setEditVarCuotas(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>Dia:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editVarDia} onChange={(e) => setEditVarDia(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>Primer Vencimiento:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editPrimerVenc} onChange={(e) => setEditPrimerVenc(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>Tarjeta de Pago:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editTarjetaPago} onChange={(e) => setEditTarjetaPago(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>Numero de Tarjeta:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editNumeroTarjeta} onChange={(e) => setEditNumeroTarjeta(e.target.value)}  />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>Vencimiento:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editVarVencimiento} onChange={(e) => setEditVarVencimiento(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>Número de Cuenta:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editNumeroCuenta} onChange={(e) => setEditNumeroCuenta(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                      <Form.Label>E-Mail:</Form.Label>
                      <Form.Control size="sm"  type="text" value={editVarEmail} onChange={(e) => setEditVarEmail(e.target.value)} />
                    </Form.Group>
                </Col>
                <Col xs={12} className="p-2">
                  <Button style={{float:'right'}} onClick={() => editarPagoHandle(filterEmis.emision_id)} variant="warning">Modificar</Button>
                </Col>
            </Row>
        </Modal.Body>
    </Modal>


    <Modal
            show={modalShow3}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose3}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Subir Documento</Modal.Title>
            </Modal.Header>
            <Modal.Body key={1}>
                <Row className="p-5">
                    <h5 className=''>DATOS DE POLIZA</h5>
                    <Col xs={12} className="p-2 ">
                        <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                            <Form.Label>Poliza:</Form.Label>
                            <Form.Control size="sm" type="text" value={filterEmis.emision_poliza?filterEmis.emision_poliza:''} onChange={(e) => setEditVarRut(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="p-2 ">
                        <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                            <Form.Label>¿Que documento deseas cargar?:</Form.Label>
                            <select value={tipo_documento} onChange={(e) => setTipo_documento(e.target.value)} class="form-control" id="tipo_documento" name="tipo_documento">
                                        <option value="selecione">Seleccione</option>
                                        <option value="poliza">Poliza</option>
                                        <option value="mandato">Mandato</option>
                                        <option value="factura">Factura</option>
                                        <option value="endoso">Endoso</option>
                                        <option value="otros">Otro Documento</option>
                                    </select>
                        </Form.Group>
                    </Col>

                    <Col xs={12} className="p-2 ">
                        <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                            <Form.Label>Cargar Factura:</Form.Label>
                            <input type="file" onChange={handleFileChange} />
                      
                        </Form.Group>
                        <Button id="botonclickSubir" onClick={()=>handleEnviarImagen(filterEmis.cotiza_id,
    filterEmis.emision_oferta,
    filterEmis.emision_poliza,
    filterEmis.emision_id,
    filterEmis.aseg_nombre)}>Cargar Poliza</Button>
                    </Col>
                </Row>
            </Modal.Body>
    </Modal>


    <Modal
            show={modalShow4}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose4}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Modificar</Modal.Title>
            </Modal.Header>
            <Modal.Body key={1}>
                <Row className="p-4" >
                    <h5 className=''>DATOS MEDIO DE PAGO</h5>
                    <Col xs={12} className="p-2 ">
                        <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                            <Form.Label>Estado:</Form.Label>
                            <select class="form-control" id="auto_estado" name="auto_estado" value={detalle} onChange={(e) => setDetalle(e.target.value)}>
                                        <option value="Seleccione">Seleccione</option>
                                        <option value="ANULADA">Anulada</option>
                                        <option value="CANCELADA">Cancelada</option>
                                    </select>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="p-2 ">
                        <Form.Group className="mb-3 p-2 cajasInfo" controlId="cotiza_anno">
                            <Form.Label>Motivo:</Form.Label>
                            <select value={Motivo} onChange={(e) => setMotivo(e.target.value)} class="form-control" id="auto_motivo" name="auto_motivo">
                                    <option value="Seleccione">Seleccione</option>
                                        <option value="Se emitió pol. Nuevamente">Se emitió pol. Nuevamente</option>
                                        <option value="Cancelada por no pago">Cancelada por no pago</option>
                                        <option value="A solicitud del cliente">A solicitud del cliente</option>
                                        <option value="Cambio de materia asegurada">Cambio de materia asegurada</option>
                                        <option value="Venta de materia asegurada sin reemplazo">Venta de materia asegurada sin reemplazo</option>
                                        <option value="Cambio de aseguradora en Es.cl">Cambio de aseguradora en Es.cl</option>
                                        <option value="Otro Motivo">Otro Motivo</option>
                                    </select>
                      
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="p-2 ">
                      <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.Fecha">
                        <label className="form-label" >Reagendamiento</label>
                        <DatePicker
                          selected={fechaBaja} // Pass the selected date
                          onChange={setFechaBaja} // Handle date selection
                        
                          dateFormat="MMM dd" // Format for the displayed date
                          customInput={<GreetingsDateInput />}
                        />
                      </Form.Group>
                    </Col>

                    <Col xs={12} className="p-2 ">
                      <Button onClick={() => darDeBaja(filterEmis.emision_id)} style={{ float:'right' }}>Dar de baja</Button>
                    </Col>
                </Row>
            </Modal.Body>
    </Modal>

      </>
    
  );
};

export default TablaEmisiones;
